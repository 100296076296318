<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useGeneralStore } from "../../stores/useGeneralStore";
import { usePkSurvey } from "@/stores/usePkSurvey";
import { useStore } from "vuex";
import ApiService from "../../common/service.api";

const vuexStore = useStore();

const pkSurvey = usePkSurvey();

const currentUser = pkSurvey.currentUser;

const startedQuiz = ref(false);
const generalStore = useGeneralStore();
const router = useRouter();
const route = useRoute();
const currentRisposta = ref(null);
const survey = computed(() => generalStore.currentSurvey);
const selectedRating = ref(null);
const hoverRating = ref(0);
const currentIndex = ref(0);
const slideDirection = ref("forward"); // Direzione di scorrimento

function startQuiz() {
  startedQuiz.value = true;

  if (survey.value.risposte.length > 0) {
    currentIndex.value = 0;
    currentRisposta.value = survey.value.risposte[currentIndex.value];
    selectedRating.value = currentRisposta.value.valore || null;
  }
}

async function saveAndGoToNext() {
  slideDirection.value = "forward";
  if (selectedRating.value !== null) {
    currentRisposta.value.valore = selectedRating.value;

    // Aggiorna la survey con la risposta corrente
    const updatedSurvey = { ...survey.value };
    updatedSurvey.risposte[currentIndex.value] = currentRisposta.value; // Assicurati di avere la logica corretta per aggiornare la risposta
    pkSurvey.setCurrentSurvey(updatedSurvey); // Salva la survey aggiornata nello store

    if (currentIndex.value < survey.value.risposte.length - 1) {
      currentIndex.value++;
      currentRisposta.value = survey.value.risposte[currentIndex.value];
      selectedRating.value = currentRisposta.value.valore || null;
    } else {
      await completeSurvey();
    }
  }
}

function goToPrevious() {
  if (currentIndex.value > 0) {
    currentRisposta.value.valore = selectedRating.value;
    slideDirection.value = "backward";
    currentIndex.value--;
    currentRisposta.value = survey.value.risposte[currentIndex.value];
    selectedRating.value = currentRisposta.value.valore || null;
  }
}

async function completeSurvey() {
  router.push("/pk-end-survey/" + route.params.idSurvey);
}

function handleRatingClick(rating) {
  if (currentRisposta.value.data_risposta || isSurveyCompleteOrDeleted())
    return;
  selectedRating.value = rating;
}

function handleHover(rating) {
  hoverRating.value = rating;
}

function handleMouseLeave() {
  hoverRating.value = 0;
}

function isSurveyCompleteOrDeleted() {
  return survey.value.completato || survey.value.eliminato;
}

async function getSurveyById(idSurvey) {
  try {
    const result = await ApiService.get();
    return result.data;
  } catch (err) {
    console.error(err);
  }
}

onMounted(async () => {
  if (!currentUser) {
    pkSurvey.setCurrentUser(vuexStore.state.user.userData);
  }

  if (route.params.idSurvey == 19) {
    console.log("id survey DICIANNOVE");
    generalStore.setCurrentSurvey({
      id: 715,
      id_survey: 19,
      id_user_survey: 715,
      id_utente_valutato: 241,
      id_utente_valutatore: 229,
      inizio: "2024-10-30T17:46:35.077",
      fine: "2024-11-09T17:46:35.077",
      eliminato: false,
      completato: null,
      nome: null,
      t_nome: {
        "it-IT": "Survey Onboarding primo mese",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      risposte: [
        {
          id: 6207,
          id_user_survey_valutatore: 715,
          domanda: {
            id: 146,
            id_survey: 19,
            id_kpi: 117,
            domanda:
              "Hai una chiara comprensione di ciò che comporta il tuo lavoro?",
            t_domanda: {
              "it-IT":
                "Hai una chiara comprensione di ciò che comporta il tuo lavoro?",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateDa: null,
            labelDa: "",
            t_labelDa: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_labelA: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_placeholder: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateA: null,
            labelA: "",
            placeholder: null,
            nota_presente: false,
            nota_obbligatoria: false,
            isNumeric: null,
            isWord: null,
            valori: [],
          },
          valore: null,
          commento: null,
          data_risposta: null,
        },
        {
          id: 6208,
          id_user_survey_valutatore: 715,
          domanda: {
            id: 147,
            id_survey: 19,
            id_kpi: 0,
            domanda:
              "In che modo il tuo ruolo corrisponde a ciò che le è stato detto durante il processo di assunzione?",
            t_domanda: {
              "it-IT":
                "In che modo il tuo ruolo corrisponde a ciò che le è stato detto durante il processo di assunzione?",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateDa: 1,
            labelDa: "Male",
            t_labelDa: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_labelA: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_placeholder: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateA: 5,
            labelA: "Bene",
            placeholder: null,
            nota_presente: false,
            nota_obbligatoria: false,
            isNumeric: null,
            isWord: null,
            valori: [],
          },
          valore: null,
          commento: null,
          data_risposta: null,
        },
        {
          id: 6209,
          id_user_survey_valutatore: 715,
          domanda: {
            id: 148,
            id_survey: 19,
            id_kpi: 0,
            domanda:
              "Hai le risorse e gli strumenti necessari per svolgere bene il tuo lavoro?",
            t_domanda: {
              "it-IT":
                "Hai le risorse e gli strumenti necessari per svolgere bene il tuo lavoro?",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateDa: 1,
            labelDa: "Male",
            t_labelDa: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_labelA: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_placeholder: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateA: 5,
            labelA: "Bene",
            placeholder: null,
            nota_presente: false,
            nota_obbligatoria: false,
            isNumeric: null,
            isWord: null,
            valori: [],
          },
          valore: null,
          commento: null,
          data_risposta: null,
        },
        {
          id: 6210,
          id_user_survey_valutatore: 715,
          domanda: {
            id: 149,
            id_survey: 19,
            id_kpi: 0,
            domanda: "Quali sfide hai affrontato finora?",
            t_domanda: {
              "it-IT": "Quali sfide hai affrontato finora?",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateDa: 1,
            labelDa: "Male",
            t_labelDa: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_labelA: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_placeholder: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateA: 5,
            labelA: "Bene",
            placeholder: null,
            nota_presente: false,
            nota_obbligatoria: false,
            isNumeric: null,
            isWord: null,
            valori: [],
          },
          valore: null,
          commento: null,
          data_risposta: null,
        },
        {
          id: 6211,
          id_user_survey_valutatore: 715,
          domanda: {
            id: 150,
            id_survey: 19,
            id_kpi: 0,
            domanda:
              "Hai ricevuto una formazione adeguata per svolgere il tuo lavoro?",
            t_domanda: {
              "it-IT":
                "Hai ricevuto una formazione adeguata per svolgere il tuo lavoro?",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateDa: 1,
            labelDa: "Male",
            t_labelDa: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_labelA: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_placeholder: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateA: 5,
            labelA: "Bene",
            placeholder: null,
            nota_presente: false,
            nota_obbligatoria: false,
            isNumeric: null,
            isWord: null,
            valori: [],
          },
          valore: null,
          commento: null,
          data_risposta: null,
        },
        {
          id: 6212,
          id_user_survey_valutatore: 715,
          domanda: {
            id: 151,
            id_survey: 19,
            id_kpi: 0,
            domanda:
              "C'è qualcosa che dobbiamo migliorare nel nostro processo di reclutamento o di onboarding? Se sì, cosa cambiereste?",
            t_domanda: {
              "it-IT":
                "C'è qualcosa che dobbiamo migliorare nel nostro processo di reclutamento o di onboarding? Se sì, cosa cambiereste?",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateDa: 1,
            labelDa: "Male",
            t_labelDa: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_labelA: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_placeholder: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateA: 5,
            labelA: "Bene",
            placeholder: null,
            nota_presente: true,
            nota_obbligatoria: true,
            isNumeric: null,
            isWord: null,
            valori: [],
          },
          valore: null,
          commento: null,
          data_risposta: null,
        },
        {
          id: 6213,
          id_user_survey_valutatore: 715,
          domanda: {
            id: 152,
            id_survey: 19,
            id_kpi: 0,
            domanda:
              "Ci sono strumenti o risorse specifiche che ritieni manchino o che potrebbero migliorare le tue prestazioni nel tuo ruolo attuale?",
            t_domanda: {
              "it-IT":
                "Ci sono strumenti o risorse specifiche che ritieni manchino o che potrebbero migliorare le tue prestazioni nel tuo ruolo attuale?",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateDa: 1,
            labelDa: "Male",
            t_labelDa: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_labelA: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_placeholder: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateA: 5,
            labelA: "Bene",
            placeholder: null,
            nota_presente: false,
            nota_obbligatoria: false,
            isNumeric: null,
            isWord: null,
            valori: [],
          },
          valore: null,
          commento: null,
          data_risposta: null,
        },
        {
          id: 6214,
          id_user_survey_valutatore: 715,
          domanda: {
            id: 153,
            id_survey: 19,
            id_kpi: 0,
            domanda:
              "Riflettendo sulla tu esperienza di onboarding, quali aspetti del processo sono stati particolarmente utili e quali aree pensi possano essere migliorate per i nuovi assunti in futuro?",
            t_domanda: {
              "it-IT":
                "Riflettendo sulla tu esperienza di onboarding, quali aspetti del processo sono stati particolarmente utili e quali aree pensi possano essere migliorate per i nuovi assunti in futuro?",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateDa: 1,
            labelDa: "Male",
            t_labelDa: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_labelA: {
              "it-IT": "Male",
              "en-EN": "Bad",
              "es-ES": "Mal",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            t_placeholder: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
              "de-DE": "",
              "fr-FR": "",
              "ml-ML": "",
              "cn-CN": "",
            },
            rateA: 5,
            labelA: "Bene",
            placeholder: null,
            nota_presente: false,
            nota_obbligatoria: false,
            isNumeric: null,
            isWord: null,
            valori: [],
          },
          valore: null,
          commento: null,
          data_risposta: null,
        },
      ],
    });
  }

  if (route.params.idSurvey && !generalStore.currentSurvey) {
    const response = await getSurveyById(route.params.idSurvey);
    generalStore.setCurrentSurvey(response);
  }

  startQuiz();
});
</script>

<template>
  <master-layout :showBack="true" smallTitle="il tuo" bigTitle="Questionario">
    <div
      class="flex flex-column justify-content-between align-items-stretch"
      style="height: calc(100vh - 150px)"
    >
      <div class="pb-4 mb-4">
        <div
          class="w-full flex flex-column justify-content-center align-items-center p-4"
        >
          <div>
            <img
              :src="currentUser.image || currentUser.avatar_url"
              style="width: 6rem; height: 6rem; border-radius: 50%"
            />
          </div>

          <div class="name font-bold text-18">
            <span>{{
              currentUser.name || currentUser.nome + " " + currentUser.cognome
            }}</span>
          </div>

          <div class="role">
            <span>{{ currentUser.role }}</span>
          </div>
        </div>
        <transition
          :name="
            slideDirection === 'forward' ? 'slide-forward' : 'slide-backward'
          "
          mode="out-in"
        >
          <div :key="currentIndex" class="page-container">
            <div class="main-image">
              <img src="../../../public/assets/img/yellowStar.png" />
            </div>
            <h4 class="text-16 mt-4 mb-2" style="color: white">
              {{
                startedQuiz ? currentRisposta?.domanda?.t_domanda["it-IT"] : ""
              }}
            </h4>

            <div class="rating-container">
              <div
                v-for="x in 5"
                :key="x"
                @click="handleRatingClick(x)"
                @mouseover="handleHover(x)"
                @mouseleave="handleMouseLeave"
                style="cursor: pointer"
              >
                <img
                  :src="
                    x <= hoverRating || x <= selectedRating
                      ? '/assets/img/fullStar.png'
                      : '/assets/img/emptyStarLight.png'
                  "
                  style="margin-left: 0.3rem; margin-right: 0.3rem"
                />
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div class="footer-container">
        <div class="button-container">
          <button
            class="w-full button-secondary p-d mr-2 text-18"
            @click="goToPrevious"
            :disabled="currentIndex === 0"
          >
            Previous
          </button>
          <button
            class="w-full button-primary p-d ml-4 text-18"
            @click="saveAndGoToNext"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 1rem 2rem;
  margin-top: 32px;
  background-color: var(--ion-color-primary);
  border-radius: 10px;
  color: var(--text-color-light);
}

.rating-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
  /* background-color: rgb(202, 202, 202); */
  padding: 0.3rem;
  border-radius: var(--ion-border-radius);
}

.rating-container img {
  transition: transform 0.2s ease-in-out;
}

.rating-container img:hover {
  transform: scale(1.2);
}

/* Bottoni "Next" e "Previous" */
.button-container {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.arrow {
  margin-left: 0.5rem;
  font-size: 1.2rem;
}

/* Animazioni sliding */
.slide-forward-enter-active,
.slide-forward-leave-active,
.slide-backward-enter-active,
.slide-backward-leave-active {
  transition: all 0.5s ease;
}

.slide-forward-enter,
.slide-backward-leave-to {
  transform: translateX(150%);
}

.slide-forward-leave-to,
.slide-backward-enter {
  transform: translateX(-150%);
}
</style>
